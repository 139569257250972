.my-container-class,
.my-header-container-class,
.my-body-class {
  width: 100%;
}
.clearAll {
  position: relative;
  width: 100%;
  clear: both;
}
.select2-container--default .select2-selection--single {
  height: auto !important;
}
.row .form-control {
  border: 1px solid #aaa;
  border-radius: 4px;
}
.card_custom {
  background: #ffe3e8 !important;
}
