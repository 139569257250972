//
// Forms
//


// Labels

.form-control-label {
    color: $gray-700;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
}


// Text inputs

.form-control {
    font-size: $input-btn-font-size;
    @include transition($transition-ease-in-out);
    height: calc(#{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border * 2.5});

    &:focus {
        &::placeholder {
            color: $input-focus-placeholder-color;
        }
    }


}

.selected-form {
    width: 100%;
    display: flex;
    flex-direction: column;

    select {
        background-color: transparent;
        border: 1px solid #dee2e6;
        padding: 0.625rem 0.75rem;
        border-radius: 0.25rem;
        box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
        @include transition($transition-ease-in-out);
        height: calc(#{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border * 2.5});

        &:focus {
            color: #8898aa;
            background-color: #fff;
            border-color: #5e72e4;
            outline: 0;
        }
    }
}

.form-control-lg {
    height: calc(#{$input-line-height * 1.5em} + #{$input-padding-y * 2} + #{$input-height-border * 2.5});

}

.form-control-sm {
    height: calc(#{$input-line-height * .3em} + #{$input-padding-y * 2} + #{$input-height-border * 2.5});

}

// Form control flush
// Removes borders and paddings from inputs and text areas

.form-control-flush {
    padding: 0;
    border-width: 0;
    background-color: transparent;
    box-shadow: none;

    &:focus {
        background-color: transparent;
        box-shadow: none;
        border-width: 0;
    }
}

// Textarea

textarea[resize="none"] {
    resize: none !important;
}

textarea[resize="both"] {
    resize: both !important;
}

textarea[resize="vertical"] {
    resize: vertical !important;
}

textarea[resize="horizontal"] {
    resize: horizontal !important;
}


// Form input variations

// Muted input

.form-control-muted {
    background-color: $input-muted-bg;
    border-color: $input-muted-bg;
    box-shadow: none;

    &:focus {
        background-color: $input-focus-muted-bg;
    }
}

.form-input-example {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.25rem + 2px);
    padding: 0.625rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #8898aa;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
    transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}


// Alternative input

.form-control-alternative {
    box-shadow: $input-alternative-box-shadow;
    border: 0;
    transition: box-shadow .15s ease;

    &:focus {
        box-shadow: $input-focus-alternative-box-shadow;
    }
}

// Size variations: Fixes to the bootstrap defaults

.form-control-lg,
.input-group-lg .form-control,
.input-group-text {
    font-size: $font-size-base !important;
}

.invalid-feedback {
    display: block !important;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #fb6340;
}

.is-invalids {
    box-shadow: $input-alternative-box-shadow-error !important;
}

.isError {
    border-color: #fb6340 !important;
}